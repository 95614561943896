<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/scss/layouts/default.scss";
</style>
